import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../../components/breadcrumb'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import Link from '../../components/link'
import List from '../../components/list'
import ListItem from '../../components/list-item'
import SEO from '../../components/seo'


export const query = graphql`
  query YearsSitemapPageQuery($genderId: PostGraphile_Gender!) {
    ...SiteInfo
    results: postgres {
      years: allYearsList(
        filter: { genders: { contains: [ $genderId ] }}
        orderBy: ID_ASC,
      ) {
        ...Year
      }
    }
  }
`

export default ({ data }) => {
  const { links, meta } = data.site.siteMetadata
  const { years } = data.results
  const { entity } = meta

  const page = `${entity.singular} Years Sitemap`
  const title = `${page} - Sitemaps`
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: 'Sitemap',
      slug: links.sitemap.home,
    },
    {
      name: 'Years',
      slug: links.sitemap.years,
    }
  ]
  const schema = {
    breadcrumb: trail,
    description: title,
    keywords,
    name: title,
    slug: links.sitemap.years,
  }

  return (
    <Layout>
      <SEO keywords={keywords} schema={schema} title={title} />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      <List>
        <ListItem>
          <Link href={links.years}>All Years</Link>
        </ListItem>
        {years.map(year => (
          <ListItem key={year.id}>
            <Link href={year.slug}>
              {year.id}
            </Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
